import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentClientAtom, myClientsAtom } from '../../recoil/atoms/Clients';
import { Client, ClientGroup } from '../../models/Client';
import ClientService from '../../services/ClientService';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import { Roles } from '../../models/Role';
import { useTranslation } from 'react-i18next';
import { ChevronIcon, ChevronType } from '../../components/shared/icon/ChevronIcon';
import { SelectListMenu } from '../../components/shared/SelectListMenu';
import { SortingOptions, SortArray, SortingKey } from '../../utils/ListUtils';
import ClientTree from '../../components/clients/ClientTree';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import usePermissions from '../../hooks/permissions/usePermissions';
import SkeletonLoader from '../../components/shared/skeleton-loader/SkeletonLoader';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../../components/shared/modal/variants/ConfirmationModal';

const Clients: FC = () => {
  const { t } = useTranslation(['client-list', 'common']);
  const [clients, setClients] = useState<Client[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [toDeleteClient, setToDeleteClient] = useState<Client | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState('');
  const [sorting, setSorting] = useState<SortingKey<ClientGroup> | null>(null);
  const navigate = useNavigate();
  const profile = useRecoilValue(currentUserAtom);
  const setCurrentClient = useSetRecoilState(currentClientAtom);
  const hasPermission = usePermissions();
  const setMyClients = useSetRecoilState(myClientsAtom);

  const fetchClients = useCallback(() => {
    setLoading(true);

    if (!profile?.id) {
      return;
    }

    ClientService.getMyClients().then((res) => {
      setMyClients(res.data);
      setClients(res.data);
      setLoading(false);
    });
  }, [profile?.id, setMyClients]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    setCurrentClient(null);
  }, [setCurrentClient]);

  const onAddClicked = (): void => {
    navigate('/clients/new');
  };

  const onSeachInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const deleteClient = () => {
    if (!toDeleteClient) {
      return;
    }

    ClientService.deleteClient(toDeleteClient.id).then(() => {
      fetchClients();
      setToDeleteClient(null);
    });
  };

  const convertToTree = useCallback((clients: Client[], parentId = null) => {
    const clientGroup = new Map();
    const others: Client[] = [];

    clients.forEach((item) => {
      if (item.parentId === parentId) {
        clientGroup.set(item.id, { hasChildren: false, ...item });
      } else {
        others.push(item);
      }
    });

    for (const key of clientGroup.keys()) {
      const children = convertToTree(others, key);
      if (children.length) {
        const group = clientGroup.get(key);
        group.hasChildren = true;
        group.children = children;
      }
    }

    return [...clientGroup.values()] as ClientGroup[];
  }, []);

  const searchClients = useCallback((clientTree: ClientGroup[], searchPhrase: string) => {
    if (!searchPhrase) {
      return clientTree;
    }
    return clientTree.reduce((result: ClientGroup[], client: ClientGroup) => {
      if (client.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) > -1) {
        result.push(client);
        return result;
      }
      const children = searchClients(client.children || [], searchPhrase);
      if (children.length) {
        result.push(Object.assign(client, { children }));
      }
      return result;
    }, []);
  }, []);

  const filteredClients = useMemo(() => {
    const clientTree = convertToTree(
      // if the parentId does not exist in the list, make it null so they still render on root level
      clients.map((client) => {
        let parentNotFound = false;
        if (!clients.find((c) => c.id === client.parentId)) {
          parentNotFound = true;
        }
        return { ...client, parentId: parentNotFound ? null : client.parentId } as Client;
      }),
    );
    let result = searchClients(clientTree, searchInput);
    if (sorting) {
      result = SortArray(result, sorting);
    }

    return result;
  }, [clients, convertToTree, searchClients, searchInput, sorting]);

  const sortingOptions = SortingOptions(t);

  const applySorting = (key: keyof ClientGroup, direction: number) => {
    setSorting(direction ? { key, direction } : null);
    setDropdownOpen('');
  };

  return (
    <div className="bg-background-1 h-full">
      <SkeletonLoader ready={!loading} type="listBlockRow" rows={10} size="medium">
        {!clients.length && (
          <>
            <TopNavPortal>
              <Heading size={HeadingSize.H1} actualSize={HeadingSize.H3}>
                {t('client-list:heading')}
              </Heading>
            </TopNavPortal>
            <div className="mx-auto flex w-1/2 flex-col items-center justify-center p-16 text-center">
              <InfoIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-4" />
              <div className="text-dpm-20 text-color-3 mt-8">
                {hasPermission(Roles.ConsultantManager) ? (
                  <span>{t('client-list:list.empty.has-permission')}</span>
                ) : (
                  <span>{t('client-list:list.empty.no-permission')}</span>
                )}
              </div>
              {hasPermission(Roles.ConsultantManager) && (
                <Button className="mt-8" onClick={onAddClicked} data-cy="client-add">
                  {t('client-list:buttons.add')}
                </Button>
              )}
            </div>
          </>
        )}
        {!!clients.length && (
          <div className="flex min-h-full flex-col">
            <TopNavPortal>
              <Heading size={HeadingSize.H1} actualSize={HeadingSize.H3}>
                {t('client-list:heading')}
              </Heading>
            </TopNavPortal>

            <div className="flex h-full flex-grow flex-col">
              <div className="flex justify-between p-4">
                <div className="w-80">
                  <SearchInput
                    value={searchInput}
                    onChange={onSeachInputChanged}
                    placeholder={t('client-list:inputs.search-placeholder')}
                    data-cy="clients-search"
                  />
                </div>
                {hasPermission(Roles.ConsultantManager) && (
                  <Button type={ButtonType.PRIMARY} onClick={onAddClicked} data-cy="client-add">
                    {t('client-list:buttons.add')}
                  </Button>
                )}
              </div>
              <div className="bg-background-1 h-full flex-grow p-4">
                <div className="flex items-end py-2">
                  <div className="text-color-1 flex flex-grow pt-4">
                    <div className="relative w-4/12 px-2">
                      {sorting?.key === 'name' && <div className="text-dpm-12">{t('client-list:list.heading.name')}</div>}
                      <span className="cursor-pointer underline" onClick={() => setDropdownOpen('client-name')}>
                        {sorting?.key !== 'name'
                          ? t('client-list:list.heading.name')
                          : sorting?.direction === -1
                            ? t('common:list.sorting.descending')
                            : t('common:list.sorting.ascending')}
                        {sorting?.key === 'name' && (
                          <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                        )}
                      </span>
                      <SelectListMenu
                        onBlur={() => setDropdownOpen('')}
                        isOpen={dropdownOpen === 'client-name'}
                        options={sortingOptions}
                        onClick={(o) => applySorting('name', o.value as number)}
                      >
                        {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                      </SelectListMenu>
                    </div>
                    <div className="relative w-2/12 px-2">
                      {sorting?.key === 'accountNumber' && <div className="text-dpm-12">{t('client-list:list.heading.account-nr')}</div>}
                      <span className="cursor-pointer underline" onClick={() => setDropdownOpen('client-accountNumber')}>
                        {sorting?.key !== 'accountNumber'
                          ? t('client-list:list.heading.account-nr')
                          : sorting?.direction === -1
                            ? t('common:list.sorting.descending')
                            : t('common:list.sorting.ascending')}
                        {sorting?.key === 'accountNumber' && (
                          <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                        )}
                      </span>
                      <SelectListMenu
                        onBlur={() => setDropdownOpen('')}
                        isOpen={dropdownOpen === 'client-accountNumber'}
                        options={sortingOptions}
                        onClick={(o) => applySorting('accountNumber', o.value as number)}
                      >
                        {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                      </SelectListMenu>
                    </div>
                    <div className="relative w-2/12 px-2">
                      {sorting?.key === 'createdUtc' && <div className="text-dpm-12">{t('client-list:list.heading.created-date')}</div>}
                      <span className="cursor-pointer underline" onClick={() => setDropdownOpen('client-createdUtc')}>
                        {sorting?.key !== 'createdUtc'
                          ? t('client-list:list.heading.created-date')
                          : sorting?.direction === -1
                            ? t('common:list.sorting.descending')
                            : t('common:list.sorting.ascending')}
                        {sorting?.key === 'createdUtc' && (
                          <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                        )}
                      </span>
                      <SelectListMenu
                        onBlur={() => setDropdownOpen('')}
                        isOpen={dropdownOpen === 'client-createdUtc'}
                        options={sortingOptions}
                        onClick={(o) => applySorting('createdUtc', o.value as number)}
                      >
                        {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                      </SelectListMenu>
                    </div>
                    <div className="w-2/12 px-2">{t('client-list:list.heading.org-compliance')}</div>
                    <div className="relative w-1/12 px-2">
                      {sorting?.key === 'highRiskCount' && <div className="text-dpm-12">{t('client-list:list.heading.high-risk-assets')}</div>}
                      <span className="cursor-pointer underline" onClick={() => setDropdownOpen('client-highRiskCount')}>
                        {sorting?.key !== 'highRiskCount'
                          ? t('client-list:list.heading.high-risk-assets')
                          : sorting?.direction === -1
                            ? t('common:list.sorting.descending')
                            : t('common:list.sorting.ascending')}
                        {sorting?.key === 'highRiskCount' && (
                          <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                        )}
                      </span>
                      <SelectListMenu
                        onBlur={() => setDropdownOpen('')}
                        isOpen={dropdownOpen === 'client-highRiskCount'}
                        options={sortingOptions}
                        onClick={(o) => applySorting('highRiskCount', o.value as number)}
                      >
                        {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                      </SelectListMenu>
                    </div>
                    <div className="w-1/12 px-2">{/* SPACER */}</div>
                    <div className="w-8">{/* SPACER */}</div>
                    <div className="w-10">{/* SPACER */}</div>
                  </div>
                </div>
                {filteredClients.length > 0 ? (
                  <ClientTree data={filteredClients} onDelete={setToDeleteClient} />
                ) : (
                  <div className="mx-auto flex w-1/2 flex-col items-center justify-center p-16 text-center">
                    <InfoIcon className="bg-primary-1 text-primary-1 my-8 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                    <div className="text-dpm-20">{t('client-list:list.filtered-empty')}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </SkeletonLoader>
      <ModalContext.Provider value={{ open: !!toDeleteClient, onClose: () => setToDeleteClient(null) }}>
        <ConfirmationModal
          title={t('client-list:modal.delete.header', { client: toDeleteClient?.name })}
          description={t('client-list:modal.delete.text')}
          cancelText={t('client-list:modal.delete.buttons.cancel')}
          confirmText={t('client-list:modal.delete.buttons.delete')}
          onCancel={() => setToDeleteClient(null)}
          onConfirm={deleteClient}
          alt
        />
      </ModalContext.Provider>
    </div>
  );
};

export default Clients;
