import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Client, ClientGroup } from '../../models/Client';
import { Roles } from '../../models/Role';
import DateUtils from '../../utils/DateUtils';
import { goToClientSpacePath } from '../../utils/NavigationUtils';
import { ContextMenuItem } from '../shared/ContextMenu';
import DataRow from '../shared/data-grid/DataRow';
import ProgressBar from '../shared/ProgressBar';
import Tooltip from '../shared/Tooltip';
import StringUtils from '../../utils/StringUtils';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { myClientsAtom } from '../../recoil/atoms/Clients';

type ClientTreeProps = {
  data: ClientGroup[];
  onDelete: (client: Client) => void;
  hasParent?: boolean;
};

const ClientTree: FC<ClientTreeProps> = (props) => {
  const { data, onDelete, hasParent = false } = props;
  const { t } = useTranslation(['client-list', 'common']);
  const hasPermission = usePermissions();
  const currentUser = useRecoilValue(currentUserAtom);
  const myClients = useRecoilValue(myClientsAtom);

  const canEdit = useMemo(() => {
    return hasPermission([Roles.Consultant, Roles.ExternalConsultant]);
  }, [hasPermission]);

  const navigate = useNavigate();

  const contextFor = (client: Client) => {
    const result: ContextMenuItem[] = [];

    if (hasPermission(Roles.ConsultantManager, client.id)) {
      result.push({
        title: t('client-list:context.details'),
        onClick: () => {
          navigate(`/clients/${client.id}`);
        },
      });
    }

    if (hasPermission(Roles.ConsultantManager)) {
      result.push({
        title: t('client-list:context.delete'),
        onClick: () => onDelete(client),
      });
    }

    return result;
  };

  return (
    <>
      {data.map((clientGroup) => (
        <div
          key={clientGroup.id}
          className={`${clientGroup.hasChildren && !hasParent ? 'bg-gray-5 px-3 pb-3' : ''}`}
          data-cy={hasParent ? 'child-client' : 'parent-client'}
        >
          <DataRow
            data-cy={`client-${clientGroup.id}`}
            url={goToClientSpacePath(clientGroup, currentUser)}
            contextMenuItems={canEdit ? contextFor(clientGroup) : undefined}
            hasChildren={clientGroup.hasChildren && !hasParent}
          >
            <div className="flex flex-grow">
              <div className={`w-4/12 text-black`}>
                <div className={`${clientGroup.hasChildren && !hasParent && 'text-dpm-16 -ml-4 font-medium'}`}>
                  {clientGroup.name}
                  {clientGroup.isTenant && myClients.filter((x) => x.isTenant).length > 1 && (
                    <span className="text-dpm-12 pl-1 font-normal italic">({t('common:tenant')})</span>
                  )}
                </div>
              </div>
              <div className="w-2/12">
                <Tooltip text={(clientGroup.accountNumber?.length ?? 0) > 15 && clientGroup.accountNumber}>
                  {(tooltip) => <div {...tooltip}>{StringUtils.truncate(clientGroup.accountNumber || '', 15)}</div>}
                </Tooltip>
              </div>
              <div className="w-2/12">
                <>{DateUtils.formatDate(new Date(clientGroup.createdUtc))}</>
              </div>
              <div className="flex w-2/12 items-center px-4">
                <ProgressBar progress={clientGroup.openAssetProgress} useProgressColours />
              </div>
              {<div className="w-1/12 px-2 text-center">{clientGroup.highRiskCount}</div>}
            </div>
          </DataRow>
          {clientGroup.children && <ClientTree data={clientGroup.children} onDelete={onDelete} hasParent={true} />}
        </div>
      ))}
    </>
  );
};

export default ClientTree;
